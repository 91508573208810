import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-location-utilization-report',
  templateUrl: './location-utilization-report.component.html',
  styleUrls: ['./location-utilization-report.component.css']
})
export class LocationUtilizationReportComponent implements OnInit {

  public loading: boolean = true;
  public location_mapping;
  public locationId;
  public report;
  
  public selectedMatch = { };
  public noMatches = { };
  public matches = [];

  constructor(private http: HttpClient, 
              public navigationService: NavigationService, 
              private route: ActivatedRoute) { }

  public calendar() {
    window.location.href = `/location/${this.locationId}/calendar`;
  }

  

  public print() {
    window.open(`/api/report/location/${this.locationId}/pdf`, '_blank');
  };

  public async toggleDropdown(open, week_of_month, day_of_week, index) {
    this.selectedMatch = { };
    this.noMatches = { };
    this.selectedMatch[ week_of_month + "" + day_of_week + "" + index ] = 0;
    this.matches = [];

    let url = `/api/location/${this.locationId}/find-provider-match/${week_of_month}/${day_of_week}/${index}`;
    this.noMatches[ week_of_month + "" + day_of_week + "" + index ] = false;
    const result = await this.http.get<any>(url).toPromise();
    if (result.length) {
            this.matches = result;
    } else {
            this.noMatches[ week_of_month + "" + day_of_week + "" + index ] = true;
    }
};

public selectMatch(week_of_month, day_of_week, index, choice) {
  this.selectedMatch[ week_of_month + "" + day_of_week + "" + index ] = choice;
};


public getTechNeededWeekSum(week_of_month, index) {
  var result = 0;
  var days_of_week = Object.keys(this.report.tech_sum[week_of_month]);
  days_of_week.forEach((day_of_week) => {
      result += this.report.tech_sum[week_of_month][day_of_week][index];
  });
  return result;
}

public calculateRequirment(value) {
  var result = ((value * 4)/40);
  return (result.toFixed(2));
}

  public async ngOnInit() {
    this.loading = true;
    this.locationId = this.route.snapshot.paramMap.get("locationId")
    this.report = await this.http.get<any>(`/api/report/location-utilization/${this.locationId}`).toPromise();
    this.location_mapping = await this.http.get<any>(`/api/location/mapping`).toPromise();
    this.loading = false;
  }
  
}
  