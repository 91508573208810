import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class NavigationService {

  constructor (private router: Router) {}

  public navigateToLogin() {
    return this.router.navigate(['login']);
  }
  public navigateToCalendar() {
    return this.router.navigate(['calendar']);
  }

  public navigateToLocationAdd() {
    return this.router.navigate(['location', 'add']);
  }

  public navigateToLocationEdit(locationId) {
    return this.router.navigate(['location', locationId, 'edit']);
  }

  public navigateToLocationChoose() {
    return this.router.navigate(['location', 'choose']);
  }

  public navigateToProviderAdd() {
    return this.router.navigate(['provider', 'add']);
  }

  public navigateToSpecialtyEdit(specialtyId) {
    return this.router.navigate(['specialty', specialtyId, 'edit']);
  }

  public navigateToSpecialtyChoose() {
    return this.router.navigate(['specialty', 'choose']);
  }

  public navigateToMatrixEdit(providerId) {
    return this.router.navigate(['matrix', providerId, 'edit']);
  }

  public navigateToMatrixChoose() {
    return this.router.navigate(['matrix', 'choose']);
  }


  public navigateToProviderEdit(providerId) {
    return this.router.navigate(['provider', providerId, 'edit']);
  }

  public navigateToProviderChoose() {
    return this.router.navigate(['provider', 'choose']);
  }

  public navigateToUserAdd() {
    return this.router.navigate(['user', 'add']);
  }

  public navigateToUserEdit(userId) {
    return this.router.navigate(['user', userId, 'edit']);
  }

  public navigateToUserChoose() {
    return this.router.navigate(['user', 'choose']);
  }


  public navigateToReportChooseProvider() {
    return this.router.navigate(['report', 'choose-provider']);
  }

  public navigateToReportChooseLocation() {
    return this.router.navigate(['report', 'choose-location']);
  }

  public navigateToReportChooseSpecialty() {
    return this.router.navigate(['report', 'choose-specialty']);
  }

  public navigateToReportLocationUtilization() {
    return this.router.navigate(['report', 'choose-location-utilization']);
  }

  public navigateToProviderReport(providerId) {
    return this.router.navigate(['report', providerId, 'provider']);
  }

  public navigateToLocationReport(locationId) {
    return this.router.navigate(['report', locationId, 'location']);
  }

  public navigateToLocationUtilizationReport(locationId) {
    return this.router.navigate(['report', locationId, 'location-utilization']);
  }

  public navigateToSpecialtyReport(specialtyId) {
    return this.router.navigate(['report', specialtyId, 'specialty']);

  }

}