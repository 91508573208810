import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class UserEditComponent implements OnInit {

  public loading: boolean = true;
  public pickList;
  public userId;
  public form: FormGroup;

  constructor(private route: ActivatedRoute, private http: HttpClient, public navigationService: NavigationService, private formBuilder: FormBuilder) { }

  public async ngOnInit() {
    this.loading = true;
    this.pickList = await this.http.get<any>(`/api/pick-list`).toPromise();
    this.userId = this.route.snapshot.paramMap.get("userId")
    const result = await this.http.get<any>(`/api/user/${this.userId}`).toPromise();
    this.form = this.formBuilder.group({
      user_id: result.user_id,
      first_name: result.first_name,
      last_name: result.last_name,
      role_id: result.role_id,
      password: ''
    });
    this.loading = false;
  }

  public async submit() {
    try {
      const result = await this.http.put<any>(`/api/user/${this.userId}`, this.form.value).toPromise();
      if (result.deleted) {
        alert ("deleted successfully");
      } else {
        alert ("updated successfully");
      }
    } catch (e) {
      alert ("something went wrong");
      console.error(e);
    }
  }

  public async delete() {
      if (confirm('Are you sure you want to delete this user')) {
        this.form.controls['deleted'].setValue(true);
          await this.submit();
          this.navigationService.navigateToLocationChoose();
    }

  };
  
}
  