import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-provider-report',
  templateUrl: './provider-report.component.html',
  styleUrls: ['./provider-report.component.css']
})
export class ProviderReportComponent implements OnInit {

  public loading: boolean = true;
  public location_mapping;
  public providerId;
  public report;
  

  constructor(private http: HttpClient, 
              public navigationService: NavigationService, 
              private route: ActivatedRoute) { }

  public calendar() {
    window.location.href = `/provider/${this.providerId}/calendar`;
  }

  public print() {
    window.open(`/api/report/provider/${this.providerId}/pdf`, '_blank');
  };

  public async ngOnInit() {
    this.loading = true;
    this.providerId = this.route.snapshot.paramMap.get("providerId")
    this.report = await this.http.get<any>(`/api/report/provider/${this.providerId}`).toPromise();
    this.location_mapping = await this.http.get<any>(`/api/location/mapping`).toPromise();
    this.loading = false;
  }
  
}
  