import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { UpgradeModule } from '@angular/upgrade/static';

import { FullCalendarModule } from '@fullcalendar/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarComponent } from './calendar.component/calendar.component';
import { HeaderComponent } from './header.component/header.component';
import { NavComponent } from './nav.component/nav.component';
import { NavigationService } from './navigation.service';
import { LoginComponent } from './login.component/login.component';
import { LocationCalendarComponent } from './location-calendar.component/location-calendar.component';
import { ChooseProviderComponent } from './report/choose-provider.component/choose-provider.component';
import { ProviderReportComponent } from './report/provider-report.component/provider-report.component';
import { LocationReportComponent } from './report/location-report.component/location-report.component';
import { ChooseLocationComponent } from './report/choose-location.component/choose-location.component';
import { ChooseSpecialtyComponent } from './report/choose-specialty.component/choose-specialty.component';
import { SpecialtyReportComponent } from './report/specialty-report.component/specialty-report.component';
import { ChooseLocationUtilizationComponent } from './report/choose-location-utilization.component/choose-location-utilization.component';
import { LocationUtilizationReportComponent } from './report/location-utilization-report.component/location-utilization-report.component';
import { ProviderChooseComponent } from './provider/choose.component/choose.component';
import { ProviderAddComponent } from './provider/add.component/add.component';
import { ProviderEditComponent } from './provider/edit.component/edit.component';
import { LocationAddComponent } from './location/add.component/add.component';
import { LocationChooseComponent } from './location/choose.component/choose.component';
import { LocationEditComponent } from './location/edit.component/edit.component';
import { UserAddComponent } from './user/add.component/add.component';
import { UserChooseComponent } from './user/choose.component/choose.component';
import { UserEditComponent } from './user/edit.component/edit.component';
import { SpecialtyChooseComponent } from './specialty/choose.component/choose.component';
import { SpecialtyEditComponent } from './specialty/edit.component/edit.component';
import { MatrixChooseComponent } from './matrix/choose.component/choose.component';
import { MatrixEditComponent } from './matrix/edit.component/edit.component';


@NgModule({
  declarations: [
    AppComponent,
    CalendarComponent,
    ChooseProviderComponent,
    ChooseLocationComponent,
    ChooseLocationUtilizationComponent,
    MatrixChooseComponent,
    MatrixEditComponent,
    SpecialtyChooseComponent,
    SpecialtyEditComponent,
    ChooseSpecialtyComponent,
    ProviderReportComponent,
    LocationReportComponent,
    LocationUtilizationReportComponent,
    SpecialtyReportComponent,
    LocationCalendarComponent,
    ProviderAddComponent,
    ProviderChooseComponent,
    ProviderEditComponent,
    LocationAddComponent,
    LocationChooseComponent,
    LocationEditComponent,
    UserAddComponent,
    UserChooseComponent,
    UserEditComponent,
    HeaderComponent,
    NavComponent,
    LoginComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FullCalendarModule,
    HttpClientModule,
    ReactiveFormsModule,
    UpgradeModule
  ],
  providers: [ 
    NavigationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
