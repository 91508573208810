import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'app-choose-specialty',
  templateUrl: './choose-specialty.component.html',
  styleUrls: ['./choose-specialty.component.css']
})
export class ChooseSpecialtyComponent implements OnInit {

  public loading: boolean = true;
  public specialties;
  public form: FormGroup;

  constructor(private http: HttpClient, public navigationService: NavigationService, private formBuilder: FormBuilder) { 
    this.form = this.formBuilder.group({
      specialty: ''
    });
  }

  public async ngOnInit() {
    this.loading = true;
    this.specialties = await this.http.get<any>(`/api/specialty`).toPromise();
    this.loading = false;
  }

  public submit() {
    this.navigationService.navigateToSpecialtyReport(this.form.controls.specialty.value);

  }
  
}
  