import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlHandlingStrategy } from '@angular/router';
import { CalendarComponent } from './calendar.component/calendar.component';
import { LoginComponent } from './login.component/login.component';
import { LocationCalendarComponent } from './location-calendar.component/location-calendar.component';
import { ChooseProviderComponent } from './report/choose-provider.component/choose-provider.component';
import { ProviderReportComponent } from './report/provider-report.component/provider-report.component';
import { ChooseLocationComponent } from './report/choose-location.component/choose-location.component';
import { LocationReportComponent } from './report/location-report.component/location-report.component';
import { ChooseSpecialtyComponent } from './report/choose-specialty.component/choose-specialty.component';
import { SpecialtyReportComponent } from './report/specialty-report.component/specialty-report.component';
import { ChooseLocationUtilizationComponent } from './report/choose-location-utilization.component/choose-location-utilization.component';
import { LocationUtilizationReportComponent } from './report/location-utilization-report.component/location-utilization-report.component';
import { ProviderChooseComponent } from './provider/choose.component/choose.component';
import { ProviderEditComponent } from './provider/edit.component/edit.component';
import { ProviderAddComponent } from './provider/add.component/add.component';
import { LocationAddComponent } from './location/add.component/add.component';
import { LocationChooseComponent } from './location/choose.component/choose.component';
import { LocationEditComponent } from './location/edit.component/edit.component';
import { UserAddComponent } from './user/add.component/add.component';
import { UserChooseComponent } from './user/choose.component/choose.component';
import { UserEditComponent } from './user/edit.component/edit.component';
import { SpecialtyChooseComponent } from './specialty/choose.component/choose.component';
import { SpecialtyEditComponent } from './specialty/edit.component/edit.component';
import { MatrixChooseComponent } from './matrix/choose.component/choose.component';
import { MatrixEditComponent } from './matrix/edit.component/edit.component';


const routes: Routes = [

  { path: 'matrix/choose', component: MatrixChooseComponent },
  { path: 'matrix/:providerId/edit', component: MatrixEditComponent },

  { path: 'specialty/choose', component: SpecialtyChooseComponent },
  { path: 'specialty/:specialtyId/edit', component: SpecialtyEditComponent },

  { path: 'location/add', component: LocationAddComponent },
  { path: 'location/choose', component: LocationChooseComponent },
  { path: 'location/:locationId/edit', component: LocationEditComponent },

  { path: 'provider/add', component: ProviderAddComponent },
  { path: 'provider/choose', component: ProviderChooseComponent },
  { path: 'provider/:providerId/edit', component: ProviderEditComponent },

  { path: 'user/add', component: UserAddComponent },
  { path: 'user/choose', component: UserChooseComponent },
  { path: 'user/:userId/edit', component: UserEditComponent },

  
  { path: 'location/:locationId/calendar', component: LocationCalendarComponent },
  { path: 'provider/:providerId/calendar', component: CalendarComponent },
  
  { path: 'report/choose-provider', component: ChooseProviderComponent },
  { path: 'report/:providerId/provider', component: ProviderReportComponent },

  { path: 'report/choose-location', component: ChooseLocationComponent },
  { path: 'report/:locationId/location', component: LocationReportComponent },

  { path: 'report/choose-location-utilization', component: ChooseLocationUtilizationComponent },
  { path: 'report/:locationId/location-utilization', component: LocationUtilizationReportComponent },

  { path: 'report/choose-specialty', component: ChooseSpecialtyComponent },
  { path: 'report/:specialtyId/specialty', component: SpecialtyReportComponent },

  { path: 'login', component: LoginComponent },
  { path: '', component: LoginComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
