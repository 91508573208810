import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../navigation.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  public isAdmin: boolean = false;
  public loading: boolean = true;
  constructor(private router: Router, private http: HttpClient, public navigationService: NavigationService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          this.ngOnInit();
      }

      if (event instanceof NavigationEnd) {
          // Hide loading indicator
      }

  });
  }

  public async ngOnInit() {
    this.loading = true;
    try {
      const user = await this.http.get<any>(`/api/whoami`).toPromise();
      this.isAdmin = user.role_id === "admin";
      this.isAdmin = true;
      this.loading = false;
    } catch (e) {
      this.logout();
    }
  }
    
  public logout() {
    this.navigationService.navigateToLogin();
  }
  
  public navigateToCalendar() {
    this.navigationService.navigateToCalendar();
  }

}
