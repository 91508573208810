import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-location-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class LocationEditComponent implements OnInit {

  public loading: boolean = true;
  public pickList;
  public locationId;
  public form: FormGroup;

  constructor(private route: ActivatedRoute, private http: HttpClient, public navigationService: NavigationService, private formBuilder: FormBuilder) { }

  public async ngOnInit() {
    this.loading = true;
    this.pickList = await this.http.get<any>(`/api/pick-list`).toPromise();
    this.locationId = this.route.snapshot.paramMap.get("locationId")
    const result = await this.http.get<any>(`/api/location/${this.locationId}`).toPromise();
    this.form = this.formBuilder.group(result);
    this.loading = false;
  }

  public async submit() {
    try {
      await this.http.put<any>(`/api/location/${this.locationId}`, this.form.value).toPromise();
      alert ("successfully updated");
    } catch (e) {
      alert ("something went wrong");
      console.error(e);
    }
  }  
}
  