import { Component, ViewChild, OnInit } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick

import * as moment from 'moment';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-calendar-component',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  
  public providerId;
  public loading = true;
  constructor(private route: ActivatedRoute, private http: HttpClient) { }
  
  @ViewChild('calendar', {static: false}) calendarComponent: FullCalendarComponent; // the #calendar in the template

  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  calendarEvents: EventInput[] = [];

  toggleVisible() {
    this.calendarVisible = !this.calendarVisible;
  }

  toggleWeekends() {
    this.calendarWeekends = !this.calendarWeekends;
  }

  gotoPast() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
  }

  addEvent(arg) {
    this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
      title: arg.title,
      start: arg.start,
      end: arg.end,
      allDay: arg.allDay
    })
  }
  handleDateClick(arg) {
    if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
      this.addEvent(arg);
    }
  }

  public async ngOnInit() {
    const providerId = this.route.snapshot.paramMap.get("providerId")
    let data;
    this.loading = true;
    try {
      data = await this.http.get<any>(`/api/report/provider/${providerId}`).toPromise();
    } catch(e){
      alert ("something went wrong");
      console.log(e);
    }
    var enumerateDaysBetweenDates = function (startDate, endDate) {
      var moments = [];
      const currDate = startDate;
      currDate.subtract(1, 'days');
      while (currDate.add(1, 'days').diff(endDate) < 0) {
        moments.push(currDate.clone());
      }

      return moments;
    };
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().add(5, 'years').endOf('month');
    const moments = enumerateDaysBetweenDates(startOfMonth, endOfMonth);
    const weekMap = {};
    moments.forEach(moment => {
      const week = moment.week();
      if (!weekMap[week]) {
        weekMap[week] = 1;
        weekMap[week + 1] = 2;
        weekMap[week + 2] = 3;
        weekMap[week + 3] = 4;
        weekMap[week + 4] = 5;
      }
      
      let day = moment.day();
      if (day > 0 && day < 6) {
        const report = data.report[weekMap[week]][day];
        if (report && report[1]) {          
          const location = data.location_mapping[report[1]];
          const title = `${data.provider.first_name} ${data.provider.last_name} - ${location}`;
          const start = moment.clone().set({ hour: '9'}).toDate();
          const end = moment.clone().set({ hour: '12'}).toDate();
          const arg = {
            allDay: false,
            end,
            start,
            title
          };
          this.addEvent(arg)
        }
        if (report && report[2]) {          
          const location = data.location_mapping[report[1]];
          const title = `${data.provider.first_name} ${data.provider.last_name} - ${location}`;
          const start = moment.clone().set({ hour: '13'}).toDate();
          const end = moment.clone().set({ hour: '17'}).toDate();
          const arg = {
            allDay: false,
            end,
            start,
            title
          };
          this.addEvent(arg)
        }
        
      }
    })
  }

}
