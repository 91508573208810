import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-matrix-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class MatrixEditComponent implements OnInit {

  public loading: boolean = true;
  public pickList;
  public matrix;
  public locations;
  public provider;
  
  public providerId;
  public form: FormGroup;
  public day_of_week_mapping;
  public session_mapping;

  constructor(private route: ActivatedRoute, private http: HttpClient, private formBuilder: FormBuilder) {
    this.day_of_week_mapping = {
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednsday",
      "4": "Thursday",
      "5": "Friday"
    }

    this.session_mapping = {
      "1": "Morning",
      "2": "Afternoon"
    }


  }

  public async ngOnInit() {
    this.loading = true;
    this.providerId = this.route.snapshot.paramMap.get("providerId")
    const result = await this.http.get<any>(`/api/matrix/${this.providerId}`).toPromise();
    this.matrix = result.matrix
    this.provider = result.provider;
    this.pickList = await this.http.get<any>(`/api/pick-list`).toPromise();
    this.locations = await this.http.get<any>(`/api/location`).toPromise();
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    this.matrix.forEach(m => {
      const items = this.form.get('items') as FormArray;
      items.push(this.formBuilder.group(m));
    })
    this.loading = false;
  }

  public async submit() {
    this.form.value.items.forEach(v => {
      v.location_id = parseInt(v.location_id);
    })
    try {
      await this.http.post<any>(`/api/matrix/${this.providerId}`, this.form.value.items).toPromise();
      alert ("updated successfully");
    } catch (e) {
      alert ("Something went wrong")
    }
  }


}
