import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-specialty-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class SpecialtyEditComponent implements OnInit {

  public loading: boolean = true;
  public pickList;
  public specialtyId;
  public form: FormGroup;

  constructor(private route: ActivatedRoute, private http: HttpClient, private formBuilder: FormBuilder) { }

  public async ngOnInit() {
    this.loading = true;
    this.specialtyId = this.route.snapshot.paramMap.get("specialtyId")
    const result = await this.http.get<any>(`/api/specialty/${this.specialtyId}`).toPromise();
    this.form = this.formBuilder.group(result);
    this.loading = false;
  }

  public async submit() {
    try {
      await this.http.put<any>(`/api/specialty/${this.specialtyId}`, this.form.value).toPromise();
      alert ("updated successfully");
    } catch (e) {
      alert ("something went wrong");
    }
  }

  
}
  