import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../navigation.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public isAdmin: boolean = true;
  public loading: boolean = true;
  public form: FormGroup;
  public user = {};
  constructor(private http: HttpClient, public navigationService: NavigationService,  private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      user_id: '',
      password: ''
    });
   }

  public async ngOnInit() {

  }
  public async login() {
    

    try {
      const authResult = await this.http.post<any>(`/api/login`, { user: this.form.value }).toPromise();
      window.localStorage.setItem('user', JSON.stringify(authResult.data));
      this.navigationService.navigateToReportChooseProvider();
    } catch (e) {
      alert ("Invalid Email & Password Combination");
    };

  }
}
  