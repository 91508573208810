import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-specialty-report',
  templateUrl: './specialty-report.component.html',
  styleUrls: ['./specialty-report.component.css']
})
export class SpecialtyReportComponent implements OnInit {

  public loading: boolean = true;
  public report;
  public specialtyId;

  constructor(private http: HttpClient, 
              public navigationService: NavigationService, 
              private route: ActivatedRoute) { }

  public calendar() {
    window.location.href = `/location/${this.specialtyId}/calendar`;
  }

  public print() {
    window.open(`/api/report/location/${this.specialtyId}/pdf`, '_blank');
  };

  public async ngOnInit() {
    this.loading = true;
    this.specialtyId = this.route.snapshot.paramMap.get("specialtyId")
    this.report = await this.http.get<any>(`/api/report/specialty/${this.specialtyId}`).toPromise();
    this.loading = false;
  }
  
}
  