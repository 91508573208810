import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'app-choose-location-utilization',
  templateUrl: './choose-location-utilization.component.html',
  styleUrls: ['./choose-location-utilization.component.css']
})
export class ChooseLocationUtilizationComponent implements OnInit {

  public loading: boolean = true;
  public locations;
  public form: FormGroup;

  constructor(private http: HttpClient, public navigationService: NavigationService, private formBuilder: FormBuilder) { 
    this.form = this.formBuilder.group({
      location: ''
    });
  }

  public async ngOnInit() {
    this.loading = true;
    this.locations = await this.http.get<any>(`/api/location`).toPromise();
    this.loading = false;
  }

  public submit() {
    this.navigationService.navigateToLocationUtilizationReport(this.form.controls.location.value);

  }
  
}
  