import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class UserAddComponent implements OnInit {

  public loading: boolean = true;
  public pickList;
  public form: FormGroup;

  constructor(private http: HttpClient, public navigationService: NavigationService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      user_id: '',
      password: '',
      first_name: '',
      last_name: '',
      role_id: ''
    });
  }

  public async ngOnInit() {
    this.loading = true;
    this.pickList = await this.http.get<any>(`/api/pick-list`).toPromise();
    this.loading = false;
  }

  public async submit() {
    try {
      const result = await this.http.post<any>(`/api/user`, this.form.value).toPromise();
      this.navigationService.navigateToUserEdit(result.user_id);
    } catch (e) {
      console.error(e);
    }
  }

}
