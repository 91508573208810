import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'app-choose-provider',
  templateUrl: './choose-provider.component.html',
  styleUrls: ['./choose-provider.component.css']
})
export class ChooseProviderComponent implements OnInit {

  public loading: boolean = true;
  public providers;
  public form: FormGroup;

  constructor(private http: HttpClient, public navigationService: NavigationService, private formBuilder: FormBuilder) { 
    this.form = this.formBuilder.group({
      provider: ''
    });
  }

  public async ngOnInit() {
    this.loading = true;
    this.providers = await this.http.get<any>(`/api/provider`).toPromise();
    this.loading = false;
  }

  public submit() {
    this.navigationService.navigateToProviderReport(this.form.controls.provider.value);

  }
  
}
  