import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-provider-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class ProviderEditComponent implements OnInit {

  public loading: boolean = true;
  public pickList;
  public providerId;
  public form: FormGroup;

  constructor(private route: ActivatedRoute, private http: HttpClient, public navigationService: NavigationService, private formBuilder: FormBuilder) { }

  public async ngOnInit() {
    this.loading = true;
    this.pickList = await this.http.get<any>(`/api/pick-list`).toPromise();
    this.providerId = this.route.snapshot.paramMap.get("providerId")
    const result = await this.http.get<any>(`/api/provider/${this.providerId}`).toPromise();
    this.form = this.formBuilder.group(result);
    this.loading = false;
  }

  public async submit() {
    try {
      const result = await this.http.put<any>(`/api/provider/${this.providerId}`, this.form.value).toPromise();
      if (result.deleted) {
        alert ("deleted successfully");
      } else {
        alert ("updated successfully");
      }
    } catch (e) {
      alert ("something went wrong");
      console.error(e);
    }
  }

  public async delete() {
      if (confirm('Are you sure you want to delete this provider')) {
        this.form.controls['deleted'].setValue(true);
          await this.submit();
          this.navigationService.navigateToProviderChoose();
    }

  };
  
}
  